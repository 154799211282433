import * as React from 'react'
import { Helmet } from 'react-helmet'

import logo from '../images/logo.png'

import '../scss/terms.scss'

const IndexPage = function() {
    const [tab, setTab] = React.useState('terms')

    return (
        <div className="terms">
            <Helmet title="Reminder" defer={false} />

            <div className="wrapper">
                <a href="/" className="logo">
                    <img src={logo} alt="" />
                </a>

                <a href="/" className="frontpage-link">Til forsiden</a>

                <div className="tabs">
                    <button className={tab === 'terms' ? 'active' : ''} onClick={() => { setTab('terms') }}>Vilkår for bruk</button>
                    <button className={tab === 'privacy' ? 'active' : ''} onClick={() => { setTab('privacy') }}>Personvernerklæring</button>
                </div>

                {
                    tab === 'terms' &&
                    <div>
                        <div className="title">Vilkår for bruk</div>

                        <div className="intro">Disse brukervilkårene regulerer din bruk av Reminder (”Tjenesten”). Ved å bruke Tjenesten aksepterer du disse brukervilkårene.</div>

                        <p>For informasjon om personvern; se vår personvernerklæring som utgjør en integrert del av brukervilkårene.</p>

                        <h2>1. Reminder</h2>
                        <p>Applikasjonen er laget for å gi deg en oversikt over dine kvitteringer, garantier, gavekort og vedlikehold.</p>
                        <p>Leverandør av applikasjonen er Reminder AS, org.nr. 926 359 134 ("Reminder").</p>

                        <h2>2. Generelt om Tjenesten – Opprettelse av brukerkonto</h2>
                        <p>Tjeneste tilbys som applikasjon på mobiltelefon (mobilapp), og kan lastes ned via Apples App Store og Google Play.</p>
                        <p>Når du har lastet ned mobilappen, må du opprette en brukerkonto med dine personopplysninger.</p>
                        <p>Det er ditt ansvar å oppgi korrekte opplysninger, både ved registrering og oppdatering, herunder å påse at opplysningene tilhører deg, eventuelt at du har nødvendig samtykke til å gjøre deg nytte av opplysningene.</p>
                        <p>Brukerkontoen er personlig. Dersom du vet eller mistenker at noen andre har tilgang til innloggingsopplysningene dine, bør du straks melde fra til Reminder.</p>

                        <h2>3. Bruk av Tjenesten</h2>
                        <h3>3.1 Bruk og misbruk</h3>
                        <p>Som bruker er du selv ansvarlig for hvordan Tjenesten brukes, herunder at du til enhver tid utøver din bruk i tråd med gjeldende lover og regler, allment akseptert praksis, samt nærværende brukervilkår.</p>
                        <p>Ved bruk av Tjenesten i strid med ovennevnte, forbeholder Reminder seg retten til uten varsel å stenge/sperre din brukerkonto midlertidig. Du vil bli varslet før en eventuell permanent stenging/sperring.</p>
                        <h3>3.2 Immaterielle rettigheter</h3>
                        <p>Alle opplysninger som registreres i Tjenesten, eies av den som har foretatt registreringen.</p>
                        <p>Enhver annen immateriell rettighet og innhold som ellers fremkommer i Tjenesten, herunder bl.a. all opphavsrett til tekst, programvare, grafikk, design og alle rettigheter til varemerker, eies av Reminder.</p>
                        <p>Som bruker er dine rettigheter i/til Tjenesten, utelukkende begrenset til ren bruk av Tjenesten. Ved å laste ned og ta i bruk Tjenesten, gis du altså en begrenset, ikke-eksklusiv, ikke-overførbar og royalty-fri bruksrett til Tjenesten. Bruksretten forutsetter at nærværende brukervilkår aksepteres av deg.</p>
                        <p>Med mindre annet uttrykkelig fremgår av brukervilkårene eller informasjon ellers gitt via Tjenesten, skal immaterielle rettigheter og innhold som tilhører Reminder verken brukes til andre formål enn det som følger av brukervilkårene, kopieres eller reproduseres i noen form, eller endres, leies ut, selges, distribueres eller utnyttes kommersielt.</p>
                        <p>Du vil på ingen måte erverve noen immaterielle rettigheter til Tjenesten utover den bruksretten som Reminder gir deg, og du erverver heller ingen rettigheter til materialet som oppstår ved bruk av Tjenesten.</p>
                        <h3>3.3 Sletting av opplysninger og/eller brukerkonto</h3>
                        <p>Du kan selv slette dine opplysninger/data i Tjenesten.</p>
                        <p>Reminder forbeholder seg retten til uten varsel å slette opplysninger/data du har registrert i Tjenesten, som enten er i strid med lov, sedvane eller nærværende brukervilkår, eventuelt som anses upassende eller i strid med formålet bak Tjenesten.</p>
                        <p>Ved ønske om å slette brukerkontoen i sin helhet, må Reminder kontaktes (se punkt 9 nedenfor). Reminder vil ved en slik henvendelse besørge sletting av brukerkontoen din innen 30 dager.</p>
                        <h3>3.4 Taushetsplikt</h3>
                        <p>Reminder har taushetsplikt overfor utenforstående, hva gjelder hvem som bruker Tjenesten og opplysningene som registreres i Tjenesten; uavhengig av hvorvidt opplysningene er registrert av deg eller på dine vegne.</p>
                        <p>Slik informasjon vil av Reminder kun bli delt med andre for det tilfellet at du har samtykket til dette, eventuelt at informasjonsdelingen følger av ufravikelig lovgivning, e.l.</p>

                        <h2>4. Leveranse av Tjenesten</h2>
                        <p>Reminder har ansvaret for forvaltningen, driften og videreutviklingen av Tjenesten. Videre har Reminder ansvaret for at alle opplysninger/data sikres forsvarlig, og at lagring/forvaltning skjer i tråd med gjeldende lovgivning.</p>
                        <p>Ved behov vil Reminder kontakte deg som bruker per e-post eller direkte i mobilappen. Dette vil bl.a. kunne omfatte oppdateringer av mobilappen, vilkårsendringer, mv.</p>
                        <p>Reminder forbeholder seg retten til å gjøre endringer i vilkårene. Slike endringer vil primært bli formidlet direkte i mobilappen. Endringer aksepteres av deg ved fortsatt bruk av Tjenesten. Skulle gjeldende lovgivning på noe tidspunkt kreve at vi innhenter din eksplisitte aksept før ikrafttredelse, vil slik aksept bli innhentet fra deg via Tjenesten. Du står fritt til å avslutte Tjenesten hvis du ikke ønsker å akseptere endringen(e).</p>
                        <p>Reminder forbeholder seg retten til uten varsel å foreta de forbedringer, endringer, tillegg eller fjerning av informasjon/funksjonalitet i Tjenesten, som Reminder anser nødvendig.</p>

                        <h2>5. Pris og betaling</h2>
                        <p>Det er gratis å laste ned Tjenesten, og du kan kostnadsfritt registrere inntil èn kvittering, e.l. i Tjenesten.</p>
                        <p>Ønsker du å registrere mer enn tre kvitteringer e.l., må du opprette et abonnement. Abonnementet opprettes direkte i Tjenesten.</p>
                        <p>Abonnementsprisen er kr 12,- per måned, og abonnementet fornyes automatisk hver måned inntil du velger å avslutte det. Reminder forbeholder seg retten til å justere abonnements-prisen med virkning fra neste abonnementsforfall. Prisjustering vil bli varslet senest 30 dager før forfall, og du står fritt til å avslutte abonnementet hvis du ikke ønsker å akseptere prisjusteringen. Ved fortsatt bruk av Tjenesten anses prisjusteringen for akseptert av deg.</p>
                        <p>Betaling for Tjenesten skjer via betalingsløsningen du bruker og har akseptert i App Store (Apple) eller Google Play, og gjennomføres automatisk ved hvert månedsskifte, inntil oppsigelse har funnet sted i tråd med nedennevnte.</p>
                        <p>Abonnementet kan sies opp uten særskilt oppsigelsesfrist eller kostnader utover det du allerede har betalt, inntil dagen før den automatiske fornyelsen. Ved oppsigelse kan du fortsatt bruke Tjenesten som normalt, ut den måneden du allerede har betalt for.</p>
                        <p>Oppsigelse av abonnementet gjøres direkte i Tjenesten. </p>
                        <p>Du vil fortsatt kunne bruke Tjenesten etter oppsigelsesmåneden, og du mister ikke dine opplastede data som følge av oppsigelsen. Samtidig er muligheten for å registrere nye kvitteringer, e.l., da igjen begrenset til tre stk. Ønsker du å registrere flere kvitteringer, e.l., må abonnementet gjenåpnes.</p>
                        <p>Ved gjenåpning av abonnementet innenfor en periode som allerede er betalt, tilkommer ingen ytterligere kostnader for deg før ordinær ny abonnementsperiode forfaller. </p>

                        <h2>6. Ansvarsfraskrivelse</h2>
                        <p>Tjenesten leveres "som den er".</p>
                        <p>Du er ansvarlig for at enhver bruk av Tjenesten skjer i tråd med gjeldende lov, sedvane og nærværende brukervilkår, og Reminder er på ingen måte ansvarlig for dine eventuelle brudd på forannevnte; verken overfor deg eller andre.</p>
                        <p>Ved å ta i bruk i Tjenesten aksepterer du å holde Reminder, herunder Reminders samarbeidspartnere, mv., skadesløs fra ethvert tap (direkte og indirekte), herunder, men ikke begrenset til, følgeskader, driftsavbrudd, tap av fortjeneste, tap av informasjon, o.l., som har noen form for sammenheng med din bruk av Tjenesten. </p>
                        <p>Endelig aksepterer du ved å ta i bruk Tjenesten, at du under ingen omstendighet kan holde Reminder ansvarlig for tap du måtte lide (direkte eller indirekte), som følge av feil eller forsinkelse i Tjenesten.</p>
                        <p>For det tilfellet at noen del av nærværende brukervilkår blir funnet i strid med ufravikelig lovgivning, skal en eventuell avtalerevisjon begrenses utelukkende til den aktuelle delen av brukervilkårene. Herunder skal det aktuelle brukervilkåret revideres slik at det kommer i tråd med ufravikelig lovgivning. Øvrige deler av brukervilkårene skal fortsatt gjelde fullt ut.</p>

                        <h2>7. Personvern</h2>
                        <p>Tjenesten innebærer innhenting, lagring og behandling av personopplysninger. Forannevnte forutsetter at det foreligger et behandlingsgrunnlag.</p>
                        <p>Reminders behandlingsgrunnlag vil for Tjenesten være dels ditt samtykke (avgitt ved bruken av Tjenesten), og dels Reminders oppfyllelse av avtalen med deg om din bruk av Tjenesten, jf. personopplysningsloven § 1, jf. personvernforordningen (forordning (EU) 2016/679) artikkel 6, punkt 1. </p>
                        <p>Det vises ellers til vår personvernerklæring.</p>

                        <h2>8. Angrerett</h2>
                        <p>Tjenesten gjelder digitalt innhold som ikke leveres på et fysisk medium. Ved nedlasting av Tjenesten gir du ditt uttrykkelige samtykke til nedlastingen og nærværende brukervilkår. Herunder samtykket du ved selve nedlastingen av Tjenesten til at angreretten din gikk tapt, jf. angrerettloven § 22 bokstav n.</p>

                        <h2>9. Lovvalg og verneting</h2>
                        <p>Brukervilkårene er underlagt norsk lov.</p>
                        <p>Tvister skal søkes løst i minnelighet. Hvis tvisten ikke kan løses ved forhandlinger, står hver av partene fritt til å bringe tvisten inn for de ordinære domstoler.</p>
                        <p>Rett verneting er Vestfold tingrett.</p>

                        <h2>10. Kontakt</h2>
                        <p>
                            Ved behov har Reminder følgende kontaktinformasjon:
                        </p>
                        <p>
                            <strong>Adresse:</strong><br/>
                            Reminder AS, Helgerødveien 83, 3228 Sandefjord
                        </p>
                        <p>
                            <strong>E-post:</strong><br/>
                            post@reminderapp.no
                        </p>


                    </div>
                }

                {
                    tab === 'privacy' &&
                    <div>
                        <span className="title">
                            Personvernerklæring for kunder av Reminder AS
                        </span>

                        <h2>Behandling av personopplysninger i Reminder AS</h2>
                        <p>Når du bruker mobilappen vår og/eller for øvrig er i kontakt med oss i anledning våre tjenester, vil Reminder AS behandle personopplysninger om deg. Nedenfor finner du derfor informasjon om personopplysninger som samles inn, hvorfor vi gjør dette og dine rettigheter knyttet til behandlingen av personopplysningene.</p>
                        <p>Behandlingsansvarlig for personopplysningene vi behandler er Reminder AS, org.nr. 926 359 134, ved daglig leder Magnus Lie Berntsen.</p>
                        <p>
                            Kontaktinformasjonen til Reminder AS er:<br/>
                            Adresse: Helgerødveien 83, 3228 Sandefjord<br/>
                            E-post: post@reminderapp.no<br/>
                            Telefon: +47 456 65 425<br/>
                            Org.nr.: 926 359 134
                        </p>
                        <p>For spørsmål du måtte ha om vår behandling av dine personopplysninger, kan du kontakte daglig leder ved bruk av ovennevnte kontaktinformasjon.</p>

                        <h2>1. Hvorfor samler vi inn personopplysninger og hva slags informasjon samler vi inn</h2>
                        <p>Vi samler inn og bruker dine personopplysninger til ulike formål avhengig av hvem du er og hvordan vi kommer i kontakt med deg. Vi samler inn følgende personopplysninger til formålene angitt her:</p>
                        <ul>
                            <li>
                                Sende ut oppdateringer, opplysninger og våre tjenester, annen markedsføring, nyhetsbrev, samt gi informasjon om vår virksomhet:
                                <ul>
                                    <li>E-post adresse/brukerkonto i mobilappen.</li>
                                </ul>
                                <p>Behandlingen skjer på grunnlag av avtale med deg/ditt samtykke.</p>
                            </li>
                            <li>
                                Besvare henvendelser som kommer inn til oss:
                                <ul>
                                    <li>Navn, telefonnummer, e-post adresse og eventuelle personopplysninger som måtte følge av henvendelsen.</li>
                                </ul>
                                <p>Behandlingen av personopplysninger skjer på grunnlag av en interesseavveining. Vi har vurdert det slik at dette ofte er nødvendig for å oss for å hjelpe deg med det du lurer på.</p>
                            </li>
                        </ul>

                        <h2>2. Utlevering av personopplysninger til andre</h2>
                        <p>Vi gir ikke personopplysningene dine videre til andre med mindre det foreligger et lovlig grunnlag for slik utlevering. Eksempler på slikt grunnlag vil typisk være en avtale med deg, eller et lovgrunnlag som pålegger oss å gi ut informasjonen.</p>
                        <p>Reminder AS bruker databehandlere til å samle inn, lagre eller på annen måte behandle personopplysninger på våre vegne. I slike tilfeller har vi inngått avtaler for å ivareta informasjonssikkerheten i alle ledd av behandlingen.  Vi benytter oss av følgende databehandlere per i dag:</p>
                        <ul>
                            <li>Koderiet AS; bistår Reminder AS med utvikling og vedlikehold av mobilappen. Koderiet AS har gjennom sitt arbeid et behov for fullstendig tilgang til mobilappen, herunder alle registrerte opplysninger/data.</li>
                            <li>Azure (EU-basert cloud-plattform eid av Microsoft); leverer servertjenester for lagring av data tilknyttet mobilappen.</li>
                        </ul>
                        <p>All behandling av personopplysninger som vi foretar skjer innenfor EU/EØS-området.</p>

                        <h2>3. Lagringstid</h2>
                        <p>Vi lagrer dine personopplysninger hos oss så lenge det er nødvendig for det formål personopplysningene ble samlet inn for. </p>
                        <p>Dette betyr for eksempel at personopplysninger som vi behandler på grunnlag av ditt samtykke slettes, hvis du trekker ditt samtykke.</p>
                        <p>Personopplysninger vi behandler for å oppfylle en avtale med deg slettes når avtalen er oppfylt, og alle plikter som følger av avtaleforholdet er oppfylt.</p>

                        <h2>4. Dine rettigheter når vi behandler personopplysninger om deg</h2>
                        <p>Du har rett til å kreve innsyn, retting eller sletting av personopplysningene vi behandler om deg. Du har videre rett til å kreve begrenset behandling, rette innsigelse mot behandlingen og kreve rett til dataportabilitet. Du kan lese mer om innholdet i disse rettighetene på Datatilsynets nettside: <a href="https://www.datatilsynet.no">www.datatilsynet.no</a>.</p>
                        <p>For å ta i bruk dine rettigheter må du kontakte oss per e-post på; post@reminderapp.no. Vi vil svare på din henvendelse til oss så fort som mulig, og senest innen 30 dager.</p>
                        <p>Vi vil be deg om å bekrefte identiteten din eller å oppgi ytterligere informasjon før vi lar deg ta i bruk dine rettigheter overfor oss. Dette gjør vi for å være sikre på at vi kun gir tilgang til dine personopplysninger til deg, og ikke noen som gir seg ut for å være deg.</p>
                        <p>Du kan til enhver tid trekke tilbake ditt samtykke for behandling av personopplysninger hos oss. Den enkleste måten å gjøre dette på, er å sende oss en e-post på; post@reminderapp.no.</p>

                        <h2>5. Klager</h2>
                        <p>Dersom du mener at vår behandling av personopplysninger ikke stemmer med det vi har beskrevet her, eller at vi på andre måter bryter personvernlovgivningen, så kan du klage til Datatilsynet.</p>
                        <p>Du finner informasjon om hvordan kontakte Datatilsynet på Datatilsynets nettsider: <a href="https://www.datatilsynet.no">www.datatilsynet.no</a>.</p>

                        <h2>6. Endringer</h2>
                        <p>Hvis det skulle skje endring av våre tjenester eller endringer i regelverket om behandling av personopplysninger, kan det medføre forandring i informasjonen du er gitt her.  Hvis vi har dine kontaktopplysninger, vil vi gjøre deg oppmerksom på disse forandringene. Ellers vil oppdatert informasjon alltid finnes lett tilgjengelig i vår mobilapp.</p>
                    </div>
                }

                <div className="submenu">
                    <a href="/password-reset">Glemt passord?</a>
                    <a href="/terms">Vilkår og personvern</a>
                </div>
            </div>

        </div>
    )
}

export default IndexPage
